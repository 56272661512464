<template>
  <div class="group-wrap">
    <faceForm @getFormParams="getFormParams"></faceForm>
    <section class="section charts-wrap">
      <div class="title">客群概览</div>
      <div class="chart-wrap" v-if="chart.data[0].rows.length">
        <template v-for="item in chart.data">
          <ve-pie width="100%" :data="item" :extend="chart.extend"></ve-pie>
        </template>
      </div>
      <empty :show="!chart.data[0].rows.length" text="暂无统计数据"></empty>
    </section>
  </div>
</template>

<script>
  import faceForm from "@/views/face/form"
  import VePie from 'v-charts/lib/pie'
  import Api from "@/views/face/api"
  import tab from "@/components/tab"
  import empty from "@/components/empty"

  export default {
    name: "group",
    components: {
      faceForm,
      tab,
      empty,
      VePie
    },
    data: () => ({
      chart: {
        query: {
          store: '',
          period: '',
          start_date_time: '',
          end_date_time: '',
        },
        data: [
          {columns: ['性别', '人数', '比例'], rows: []},
          {columns: ['年龄段', '人数', '比例'], rows: []},
        ],
        extend: {
          series: {
            type: 'pie',
            radius: '50%',
            label: {
              show: true,
              position: 'outside',
              formatter: params => {
                let {name, value, percent} = params
                return `${name}:${value}人\n比例:${percent}%`
              }
            }
          }
        }
      },
    }),
    methods: {
      getFormParams(params) {
        Object.keys(params).forEach(key => this.chart.query[key] = params[key])
        this.getStatistics()
      },
      getStatistics() {
        Api.group(this.chart.query).then(res => {
          if (res.data) {
            const {genderPie, ageTypePie} = res.data
            if (genderPie && ageTypePie) {
              this.chart.data[0].rows = genderPie.dataList.map(item => ({'性别': item.name, '人数': item.value, '比例': item.percentage}))
              this.chart.data[1].rows = ageTypePie.dataList.map(item => ({'年龄段': item.name, '人数': item.value, '比例': item.percentage}))
            } else {
              this.chart.data[0].rows = []
              this.chart.data[1].rows = []
            }
          } else {
            this.chart.data[0].rows = []
            this.chart.data[1].rows = []
          }
        }).catch(err => {
          console.log(err);
        })
      },
    },
  }
</script>

<style scoped lang="scss">
  .charts-wrap {
    .title {
      color: #333;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .tab-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      &::v-deep {
        .radio-group {
          margin: initial;
        }
      }
    }
  }
</style>