<template>
  <section class="section form-wrap">
    <el-form class="form" ref="form" size="mini" label-width="80px" label-position="right" :model="form.data">
      <el-row>
        <el-col :xl="4" :lg="24" :md="24" :sm="24">
          <el-form-item label-width="0px" style="text-align: center;">
            <tab :tabs="tabs" @handleTabClick="handleTabClick"></tab>
          </el-form-item>
        </el-col>
        <el-col :xl="4" :lg="6" :md="6" :sm="12">
          <el-form-item label="日期范围:">
            <el-date-picker style="width: 100%;" v-show="form.data.period === 'month'" clearable size="mini" type="month" align="right" placeholder="选择月份" format="yyyy-MM" value-format="yyyy-MM" v-model="form.date[0]" @change="getDateRange"></el-date-picker>
            <el-date-picker style="width: 100%;" v-show="form.data.period === 'week'" clearabl e size="mini" type="week" align="right" placeholder="选择周数" format="yyyy 第 WW 周" v-model="form.week" @change="getWeekDateRange"></el-date-picker>
            <el-date-picker style="width: 100%;" v-show="form.data.period === 'day'" clearable size="mini" type="datetime" align="right" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" v-model="form.date[0]" @change="getCustomDateRange"></el-date-picker>
            <el-time-picker style="width: 100%;" v-show="form.data.period === 'day'" is-range clearable size="mini" align="right" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" format="HH:mm:ss" value-format="HH:mm:ss" v-model="form.time" @change="getCustomDateRange"></el-time-picker>
            <el-date-picker style="width: 100%;" v-show="['year', 'quarter'].includes(form.data.period)" clearable size="mini" type="year" align="right" placeholder="选择年份" format="yyyy" value-format="yyyy" v-model="form.date[0]" @change="getDateRange"></el-date-picker>
            <el-date-picker style="width: 100%;" v-show="form.data.period === 'custom'" clearable size="mini" type="datetime" align="right" placeholder="开始日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="form.date[0]" @change="getCustomDateRange"></el-date-picker>
            <el-date-picker style="width: 100%;" v-show="form.data.period === 'custom'" clearable size="mini" type="datetime" align="right" placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="form.date[1]" @change="getCustomDateRange"></el-date-picker>
            <el-select filterable v-if="form.data.period === 'quarter'" v-model="form.quarter" @change="getDateRange"><el-option v-for="item in list.quarter" :key="item.value" :label="item.label" :value="item.value"></el-option></el-select>
          </el-form-item>
        </el-col>
        <el-col :xl="4" :lg="6" :md="6" :sm="12">
          <el-form-item label="门店:">
            <treeSelect :radio="['/ranking', '/group'].includes($route.path)" v-model="form.data.store" @change="submit"></treeSelect>
          </el-form-item>
        </el-col>
        <template v-if="$route.path !== '/group'">
          <el-col :xl="4" :lg="6" :md="6" :sm="12">
            <el-form-item label="性别:">
              <el-select placeholder="请选择性别" clearable filterable v-model="form.data.gender" @change="submit">
                <el-option v-for="item in list.sex" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="4" :lg="6" :md="6" :sm="12">
            <el-form-item label="年龄段:">
              <el-select placeholder="请选择年龄段" clearable filterable v-model="form.data.age_type" @change="submit">
                <el-option v-for="item in list.age" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </section>
</template>

<script>
  import tab from "@/components/tab"
  import treeSelect from "@/components/treeSelect"
  import Api from "./api"
  import {message} from "@/utils";

  export default {
    name: "faceForm",
    components: {
      tab,
      treeSelect
    },
    data: () => ({
      tabs: [
        {label: '日', value: 'day'},
        {label: '周', value: 'week'},
        {label: '月', value: 'month'},
        {label: '季', value: 'quarter'},
        {label: '年', value: 'year'},
        // {label: '特殊日', value: 'promotion'},
        {label: '自定义', value: 'custom'},
      ],
      // 表单查询参数
      form: {
        date: ['', ''],
        quarter: 1,
        week: '',
        time: ['00:00:00', '23:59:59'],
        data: {
          store: '',
          period: 'day',
          gender: 'all',
          age_type: 'all',
          start_date_time: '',
          end_date_time: '',
          order: 'desc',
          page_no: 1,
          page_size: 10
        }
      },
      // 下拉列表
      list: {
        quarter: [
          {label: '第一季度', value: 1},
          {label: '第二季度', value: 2},
          {label: '第三季度', value: 3},
          {label: '第四季度', value: 4},
        ],
        week: [
          {label: '第一周', value: 1},
          {label: '第二周', value: 2},
          {label: '第三周', value: 3},
          {label: '第四周', value: 4},
        ],
        store: [],
        sex: [],
        age: [],
      },
      // 地址栏参数
      params: {
        realname: '',
        phone: '',
        company: '',
        department: '',
        position: '',
        age: '',
        power: ''
      }
    }),
    methods: {
      getAge() {
        Api.age().then(res => {
          this.list.age = res.data
        }).catch(err => {
          console.log(err);
        })
      },
      getSex() {
        Api.sex().then(res => {
          this.list.sex = res.data
        }).catch(err => {
          console.log(err);
        })
      },
      handleTabClick(data) {
        const date = this.$moment()
        this.form.quarter = 1
        this.form.data.period = data
        this.form.date = [date.format('YYYY-MM-DD HH:mm:ss'), date.add(1, 'day').format('YYYY-MM-DD HH:mm:ss')]
        this.form.data.end_date_time = ''
        this.form.data.start_date_time = ''
        this.form.time = ['00:00:00', '23:59:59']
        if (data === 'week') {
          this.form.week = new Date()
          this.getWeekDateRange()
        } else if (['day', 'custom'].includes(data)) {
          this.getCustomDateRange()
        } else {
          this.getDateRange()
        }
      },
      // 获取日期范围(月、年、季)
      getDateRange() {
        const type = this.form.data.period
        let moment = this.$moment(this.form.date[0])
        if (type === 'quarter' && this.form.quarter && this.form.date[0]) {
          moment = moment.quarters(this.form.quarter)
        }
        this.form.data.start_date_time = moment.startOf(type).format('YYYY-MM-DDTHH:mm:ss')
        this.form.data.end_date_time = moment.endOf(type).format('YYYY-MM-DDTHH:mm:ss')
        this.submit()
      },
      // 获取日期范围(日、自定义)
      getCustomDateRange() {
        if (this.form.data.period === 'custom') {
          if (this.$moment(this.form.date[0]).valueOf() > this.$moment(this.form.date[1]).valueOf()) {
            message('开始时间不得大于结束时间！', 'error')
            return false
          }
          this.form.data.start_date_time = this.form.date[0].replace(' ', 'T')
          this.form.data.end_date_time = this.form.date[1].replace(' ', 'T')
        } else {
          this.form.data.start_date_time = `${this.form.date[0].split(' ')[0]}T${this.form.time[0]}`
          this.form.data.end_date_time = `${this.form.date[0].split(' ')[0]}T${this.form.time[1]}`
        }
        this.submit()
      },
      // 获取日期范围(周)
      getWeekDateRange() {
        if (this.form.week) {
          const moment = this.$moment(this.form.week.valueOf())
          this.form.data.start_date_time = moment.startOf('week').format('YYYY-MM-DDTHH:mm:ss')
          this.form.data.end_date_time = moment.endOf('week').format('YYYY-MM-DDTHH:mm:ss')
          this.submit()
        }
      },
      submit() {
        if (this.form.data.start_date_time && this.form.data.end_date_time) {
          this.$emit('getFormParams', this.form.data)
        }
      }
    },
    created() {
      this.params = this.$route.query
      this.getSex()
      this.getAge()
      this.handleTabClick('day')
    }
  }
</script>

<style scoped lang="scss">
  .form-wrap {
    .form {
      .el-form-item,
      .el-select,
      .el-input,
      .el-range-editor.el-input__inner {
        width: 100%;
      }
    }
  }
</style>